// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/opt/palmier/salt/src/models/global';
import model_2 from '/opt/palmier/salt/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'global', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
