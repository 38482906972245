// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import DashboardOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/DashboardOutlined';
import ApartmentOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ApartmentOutlined';
import UserOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/UserOutlined';
import TeamOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import AppstoreOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/AppstoreOutlined';
import BlockOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/BlockOutlined';
import FileOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/FileOutlined';
import KeyOutlined from '/opt/palmier/salt/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/KeyOutlined';
export default { DashboardOutlined, ApartmentOutlined, UserOutlined, TeamOutlined, AppstoreOutlined, BlockOutlined, FileOutlined, KeyOutlined };
