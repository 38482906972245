import { Generator, Logger } from '@-/oxygen';

setTimeout(() => {
  // 输出产品信息
  Logger.capsule('Two Factor Technology > Food > Palmier', 'Build20241001.GQ');
  // -
  Generator.forAppLayout();
}, 0);

const { getInitialState, layout, locale, request, rootContainer } =
  Generator.forApp();

export { getInitialState, layout, locale, request, rootContainer };
